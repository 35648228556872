import React from "react"
import { Container } from "react-bootstrap"

import { graphql } from "gatsby"
import classNames from "classnames"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"

import { useResourcesData } from "hooks/contentful/pages/useResourcesData"

import Layout from "components/Layout"
import ExternalLink from "components/Modal/ExternalLink"
import SEO from "components/Seo"
import PageTitle from "components/PageTitle/PageTitle"
import TabbedNav from "components/TabbedNav/TabbedNav"

import CardA1 from "../images/resources-a-1.png"
import CardA3 from "../images/resources-a-3.png"
import CardA4 from "../images/resources-a-4.png"
import CardA5 from "../images/resources-a-5.png"
import CardA6 from "../images/resources-a-6.png"
import CardA7 from "../images/resources-a-7.png"
import CardA8 from "../images/resources-a-8.png"
import CardA9 from "../images/resources-a-9.png"
import CardB1 from "../images/resources-b-1.png"
import CardB2 from "../images/resources-b-2.png"
import CardB3 from "../images/resources-b-3.png"
import CardB4 from "../images/resources-b-4.png"
import CardB5 from "../images/resources-b-5.png"
import CardB6 from "../images/resources-b-6.png"
import WebIcon from "../images/website-icon.png"
import UpDoseIcon from "../images/resources-up-dose-form.png"

import DownloadIcon from "assets/svg/download-icon"
import ExternalLinkIcon from "assets/svg/external-link"

import "../styles/main.scss"
import styles from "../styles/pages/resources.module.scss"

const IMAGES = {
  CardA1: CardA1,
  CardA3: CardA3,
  CardA4: CardA4,
  CardA5: CardA5,
  CardA6: CardA6,
  CardA7: CardA7,
  CardA8: CardA8,
  CardA9: CardA9,
  CardB1: CardB1,
  CardB2: CardB2,
  CardB3: CardB3,
  CardB4: CardB4,
  CardB5: CardB5,
  CardB6: CardB6,
  UpDoseIcon: UpDoseIcon,
  WebIcon: WebIcon,
}

const Resources = ({ data, location }) => {
  const { header, tabs, topic, topicB, cardA, cardB } = useResourcesData()

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout>
        <SEO title={data.page.title} description={data.page.description}/>
        <main className={styles.resources}>
          <PageTitle title={header.title}></PageTitle>
          <TabbedNav hash={location.hash}tabs={tabs} className={styles.tabs} />
          <Container fluid as={"section"} className={styles.resourcesSection}>
            <Container>
              <h2 className={styles.header}>{topic.header}</h2>
              <p className={styles.topic}>{topic.topic}</p>
              <p className={styles.topic}>{topicB.topic}</p>
            </Container>
          </Container>
          <Container
            fluid
            id={"practice"}
            as={"section"}
            className={classNames([styles.cardContainer, styles.cardA])}
          >
            <Container>
              <h3 className={styles.cardHeader}>{cardA.header}</h3>
              <ul className={styles.cardList}>
                {cardA.cards.map(({ image, alt, body, cta }, index) => {
                  return (
                    <li key={index} className={styles.ctaCard}>
                      <div className={styles.ctaBodyContainer}>
                        <p className={styles.ctaBody}>{body}</p>
                        <img
                          alt={alt}
                          className={styles.cardImage}
                          src={IMAGES[image]}
                        />
                      </div>
                      {
                      <a
                        className={styles.cta}
                        href={cta.url}
                        target={"_blank"}
                        rel="noreferrer"
                        aria-label={cta.ariaLabel}
                      >
                        {cta.label}
                        {cta.showFileIcon ? (
                          <a className={styles.cta} href={cta.url} target={cta.target}>
                            {cta.label}
                            <DownloadIcon classes={`${styles.icon} ${styles.downloadIcon}`} />
                          </a>
                        ) : (
                          <ExternalLink className={styles.cta} url={cta.url}>
                            {cta.label}
                            <ExternalLinkIcon classes={styles.icon} />
                          </ExternalLink>
                        )}
                        />
                      </a>
                      }
                    </li>
                  )
                })}
              </ul>
            </Container>
          </Container>
          <Container
            fluid
            id={"educational"}
            as={"section"}
            className={classNames([styles.cardContainer, styles.cardB])}
          >
            <Container>
              <h3 className={styles.cardHeader}>{cardB.header}</h3>
              <ul className={styles.cardList}>
                {cardB.cards.map(({ image, alt, body, cta }, index) => {
                  return (
                    <li key={index} className={styles.ctaCard}>
                      <div className={styles.ctaBodyContainer}>
                        <p className={styles.ctaBody}>{body}</p>
                        <img
                          alt={alt}
                          className={styles.cardImage}
                          src={IMAGES[image]}
                        />
                      </div>
                      <a
                        className={styles.cta}
                        href={cta.url}
                        target={"_blank"}
                        rel="noreferrer"
                        aria-label={cta.ariaLabel}
                      >
                        {cta.label}
                        <DownloadIcon
                          classes={`${styles.icon} ${styles.downloadIcon}`}
                        />
                      </a>

                    </li>
                  )
                })}
              </ul>
            </Container>
          </Container>
        </main>
      </Layout>
    </StateProvider>
  )
}

export default Resources

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "Resources" }) {
      label
      url
      title
      description
    }
  }
`
